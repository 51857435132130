@if (onboardingService.currentStep !== undefined) {
    @if (onboardingService.isExistingCustomer) {
        <init-existing-customer />
    } @else {
        @switch (onboardingService.currentStep.stepType) {
            @case (stepType.SELECT_CONTRACTTYPE) {
                <init-select-contracttype />
            }
            @case (stepType.SELECT_COMPANY) {
                <init-select-company />
            }
            @case (stepType.TAX_TYPE) {
                <init-tax-type />
            }
            @case (stepType.BANK_SERVICES) {
                <init-bank-services />
            }
            @case (stepType.DEFAULT_BANK_ACCOUNT) {
                <init-default-bank-account />
            }
            @case (stepType.EXISTING_RECONCILIATION) {
                <init-existing-reconciliation />
            }
            @case (stepType.BANK_ACCOUNTS) {
                <init-bank-accounts />
            }
            @case (stepType.CONFIRM_AND_CREATE) {
                <init-confirm-and-create />
            }
            @case (stepType.COMPANY_CREATED) {
                <init-company-created />
            }
            @default {
                Oops
            }
        }

        <section
            class="nav-buttons"
            *ngIf="!onboardingService.currentStep.hideGoBackButton || !onboardingService.currentStep.hideGoNextButton"
        >
            <button
                *ngIf="!onboardingService.currentStep.hideGoBackButton"
                class="secondary"
                (click)="onboardingService.goBack()"
            >
                {{ onboardingService.currentStep.goBackLabel || 'Tilbake' }}
            </button>

            <button
                *ngIf="!onboardingService.currentStep.hideGoNextButton"
                class="c2a"
                (click)="onboardingService.goNext()"
            >
                {{ onboardingService.currentStep.goNextLabel || nextButtonLabel }}
            </button>
        </section>
    }
}
