<h1 class="finished-header">Gratulerer, SpareBank 1 Regnskap er nå klar til bruk!</h1>

<p class="mb-3">Gratulerer, regnskapsprogrammet er nå klart!</p>

<button class="good mb-2" (click)="setActiveCompany()">Gå til SpareBank 1 Regnskap</button>

@if (orderOcrLink) {
    <section class="order-ocr-container">
        <p class="order-ocr-header">
            Sende mange fakturaer? Faktura med KID gjør det enkelt å vite hvem som har betalt
        </p>
        <p>
            Faktura med KID-nummer gjør det enkelt å vite hvem som har betalt. Du kan bestille dette ved å gå til
            bestillingsskjema (klikk på knappen under).
        </p>
        <button class="c2a" (click)="orderOcr()">Bestill faktura med KID (OCR)</button>
    </section>
}

<h2 class="mt-3 summary-header">Oppsummering av din bestilling</h2>
<!-- uncomment when email receipt is implemented -->
<!-- make sure Customer.ContactEmail is correct, it might be currentUser.Email -->
<!-- <p class="mb-2">Informasjon om din bestilling er sendt til {{onboardingService.state()?.Customer?.ContactEmail}}</p> -->

<h2 class="mt-2">Foretak</h2>
<p class="mb-4">{{ onboardingService.state()?.Customer?.Name }}</p>

<h2 class="mt-4">Tjeneste: SpareBank 1 Regnskap</h2>

<section class="selected-bank-service">
    <svg fill="none" width="22" viewBox="0 0 24 24">
        <path d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8" stroke="#008A00" stroke-width="2.5" stroke-linecap="round" />
    </svg>
    <span>Regnskapsprogram</span>
</section>

@if (onboardingService.state()?.BankServices?.HasPayments) {
    <section class="selected-bank-service">
        <svg fill="none" width="22" viewBox="0 0 24 24">
            <path d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8" stroke="#008A00" stroke-width="2.5" stroke-linecap="round" />
        </svg>
        <span>
            {{
                onboardingService.state().BankServices?.HasPreApprovedPayment
                    ? 'Du kan betale og godkjenne betalinger fra regnskapsprogrammet'
                    : 'Du kan betale fra regnskapsprogrammet, men betalingene må ettergodkjennes i nettbanken'
            }}
        </span>
    </section>
}

@if (onboardingService.state()?.BankServices?.HasStatements) {
    <section class="selected-bank-service">
        <svg fill="none" width="22" viewBox="0 0 24 24">
            <path d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8" stroke="#008A00" stroke-width="2.5" stroke-linecap="round" />
        </svg>
        <span>
            Du får informasjon om inn- og utbetalinger i regnskapsprogrammet, oppstart for banksavstemming
            {{ onboardingService.state()?.BankServices?.BankStatementStartDate | date: 'dd.MM.yyyy' }}
        </span>
    </section>
}

@if (onboardingService.bankAccountsState()?.bankAccounts?.length) {
    <section class="mt-4">
        <init-bank-accounts-table
            [bankAccounts]="onboardingService.bankAccountsState().bankAccounts"
            [editable]="false"
        />
    </section>
}
