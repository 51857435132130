<section class="contract-types" *ngIf="onboardingService?.contractTypes?.length">
    <section class="contract-type-grid">
        <contract-type-card
            *ngFor="let contractType of onboardingService.contractTypes"
            buttonClass="{{ isSb1 && isContractActivation ? 'c2a' : '' }}"
            [contractType]="contractType"
            [recommended]="contractType.Name === 'Plus' && (isDnb || isEika)"
            (selected)="onContractTypeSelected(contractType.ContractType)"
        >
        </contract-type-card>
    </section>

    <!-- <a class="try-demo-link" *ngIf="isDnb && showTryDemoLink" routerLink="/init/register-company?type=demo">Jeg vil heller prøve et demo selskap</a> -->

    <contract-types-comparison [contractTypes]="onboardingService.contractTypes"></contract-types-comparison>
</section>
