import { NgModule } from '@angular/core';
import { UniFrameworkModule } from '../../../framework/frameworkModule';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../layout/layoutModule';
import { AppCommonModule } from '../common/appCommonModule';
import { ConfirmAndCreate } from './company-creation-wizard/confirm-and-create/confirm-and-create';
import { OnboardingWrapper } from './company-creation-wizard/onboarding-wrapper';
import { SelectCompany } from './company-creation-wizard/select-company/select-company';
import { SelectContractType } from './company-creation-wizard/select-contracttype/select-contracttype';
import { InitTaxType } from './company-creation-wizard/tax-type/tax-type';
import { DnbSelectCompany } from './company-creation-wizard/select-company/dnb/dnb-select-company';
import { Sb1SelectCompany } from './company-creation-wizard/select-company/sb1/sb1-select-company';
import { EikaSelectCompany } from './company-creation-wizard/select-company/eika/eika-select-company';
import { BankServices } from './company-creation-wizard/bank-services/bank-services';
import { EikaBankServices } from './company-creation-wizard/bank-services/eika/eika-bank-services';
import { DefaultBankAccount } from './company-creation-wizard/default-bank-account/default-bank-account';
import { EikaDefaultBankAccount } from './company-creation-wizard/default-bank-account/eika/eika-default-bank-account';
import { CompanyCreated } from './company-creation-wizard/company-created/company-created';
import { EikaCompanyCreated } from './company-creation-wizard/company-created/eika/eika-company-created';
import { EikaConfirmAndCreate } from './company-creation-wizard/confirm-and-create/eika/eika-confirm-and-create';
import { ExistingCustomer } from './company-creation-wizard/existing-customer/existing-customer';
import { SB1BankServices } from './company-creation-wizard/bank-services/sb1/sb1-bank-services';
import { SB1DefaultBankAccount } from './company-creation-wizard/default-bank-account/sb1/sb1-default-bank-account';
import { ExistingReconciliation } from './company-creation-wizard/existing-reconciliation/existing-reconciliation';
import { InitBankAccounts } from './company-creation-wizard/bank-accounts/bank-accounts';
import { SB1BankAccounts } from './company-creation-wizard/bank-accounts/sb1/sb1-bank-accounts';
import { InitBankAccountsTable } from './company-creation-wizard/bank-accounts/bank-accounts-table/bank-accounts-table';
import { Sb1ConfirmAndCreate } from './company-creation-wizard/confirm-and-create/sb1/sb1-confirm-and-create';
import { Sb1CompanyCreated } from './company-creation-wizard/company-created/sb1/sb1-company-created';
import { Sb1ExistingReconciliation } from './company-creation-wizard/existing-reconciliation/sb1/sb1-existing-reconciliation';

@NgModule({
    imports: [UniFrameworkModule, RouterModule, LayoutModule, AppCommonModule],
    declarations: [
        OnboardingWrapper,
        SelectContractType,
        SelectCompany,
        InitTaxType,
        ConfirmAndCreate,
        DnbSelectCompany,
        Sb1SelectCompany,
        EikaSelectCompany,
        BankServices,
        EikaBankServices,
        SB1BankServices,
        DefaultBankAccount,
        EikaDefaultBankAccount,
        CompanyCreated,
        EikaCompanyCreated,
        ConfirmAndCreate,
        EikaConfirmAndCreate,
        ExistingCustomer,
        SB1DefaultBankAccount,
        ExistingReconciliation,
        InitBankAccounts,
        InitBankAccountsTable,
        Sb1ExistingReconciliation,
        SB1BankAccounts,
        Sb1ConfirmAndCreate,
        Sb1CompanyCreated,
    ],
    exports: [
        OnboardingWrapper,
        SelectContractType,
        SelectCompany,
        InitTaxType,
        ConfirmAndCreate,
        DnbSelectCompany,
        Sb1SelectCompany,
        EikaSelectCompany,
        BankServices,
        EikaBankServices,
        SB1BankServices,
        DefaultBankAccount,
        EikaDefaultBankAccount,
        CompanyCreated,
        EikaCompanyCreated,
        ConfirmAndCreate,
        EikaConfirmAndCreate,
        SB1DefaultBankAccount,
        ExistingReconciliation,
        InitBankAccounts,
        InitBankAccountsTable,
        Sb1ExistingReconciliation,
        SB1BankAccounts,
        Sb1ConfirmAndCreate,
        Sb1CompanyCreated,
    ],
})
export class OnboardingModule {}
