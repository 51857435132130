import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AuthService } from '@app/authService';
import { CompanyService } from '@app/services/common/companyService';

@Component({
    selector: 'init-eika-company-created',
    templateUrl: './eika-company-created.html',
    styleUrls: ['./eika-company-created.sass'],
})
export class EikaCompanyCreated {
    constructor(
        public onboardingService: OnboardingService,
        private authService: AuthService,
        private companyService: CompanyService,
    ) {}

    ngOnInit() {
        // do we need logic here?
    }

    setActiveCompany() {
        // don't remember why this is here, but the sb1 branch (baap-wip) has it, so it might be important
        this.companyService.invalidateCache();
        this.onboardingService.clearSessionStorage();
        this.authService.setActiveCompany(this.onboardingService.createdCompany, '/');
    }
}
