import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../onboarding-service';
import { Router } from '@angular/router';

@Component({
    selector: 'init-existing-customer',
    templateUrl: './existing-customer.html',
    styleUrls: ['./existing-customer.sass'],
})
export class ExistingCustomer {
    appName = theme.appName;
    themes = THEMES;

    constructor(
        private service: OnboardingService,
        private router: Router,
    ) {}

    next(createNew: boolean) {
        if (createNew) {
            sessionStorage.setItem('INIT_EXISTING_CUSTOMER', 'false');
            this.service.isExistingCustomer = false;
        } else {
            this.router.navigateByUrl('init/login');
        }
    }
}
