import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../onboarding-service';

@Component({
    selector: 'init-select-contracttype',
    templateUrl: './select-contracttype.html',
    styleUrls: ['./select-contracttype.sass'],
})
export class SelectContractType {
    isSb1 = theme.theme === THEMES.SR;
    isDnb = theme.theme === THEMES.EXT02;
    isEika = theme.theme === THEMES.EIKA;

    constructor(public onboardingService: OnboardingService) {}

    ngOnInit() {}

    onContractTypeSelected(contractType: number) {
        this.onboardingService.setSelectedContractType(contractType);
    }
}
