<h1 class="mt-2 mb-2">DNB SELECT COMPANY</h1>
<section class="alert">
    Mock some Unit of Work values. Writes to the store and saves in sessionStorage on focus lost.
</section>

<section>
    <form [formGroup]="mockForm">
        <label #customerNameInput class="uni-label">
            <span>Customer Name</span>
            <input type="text" formControlName="CustomerName" />
        </label>

        <label class="uni-label">
            <span>Contract Name</span>
            <input type="text" formControlName="ContractName" />
        </label>

        <label class="uni-label">
            <span>Company Name</span>
            <input type="text" formControlName="CompanyName" />
        </label>

        <label class="uni-label">
            <span>User Name</span>
            <input type="text" formControlName="UserName" />
        </label>
    </form>
</section>
