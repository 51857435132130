<h1 style="font-size: 32px">Takk for din bestilling</h1>

<p class="mt-4 mb-3">Eika Regnskap er nå klart til bruk!</p>

<button class="good-button mb-2" (click)="setActiveCompany()">Gå til Eika Regnskap</button>

<h1 class="mt-4">Oppsummering av din bestilling</h1>
<!-- uncomment when email receipt is implemented -->
<!-- <p class="mb-2">Informasjon om din bestilling er sendt til {{onboardingService.state()?.Customer?.ContactEmail}}</p> -->

<h2 class="mt-4">Foretak</h2>
<p class="mb-4">
    {{ onboardingService.state()?.Customer?.Name }}{{ onboardingService.state()?.Customer?.OrgNumber ? ',' : '' }}
    <span style="display: inline-block" *ngIf="onboardingService.state()?.Customer?.OrgNumber">
        Org.nr {{ onboardingService.state()?.Customer?.OrgNumber | uninumberformat: 'orgno' }}
    </span>
</p>

<h2 class="mt-4">Valgte tjenester</h2>

<section class="selected-bank-service">
    <i class="material-icons color-good">task_alt</i>
    <span>Regnskapsprogram fra Eika Regnskap</span>
</section>

<section class="selected-bank-service" *ngIf="onboardingService.state()?.BankServices?.HasPayments">
    <i class="material-icons color-good">task_alt</i>
    <span>
        {{
            onboardingService.state().BankServices?.HasPreApprovedPayment
                ? 'Du kan betale og godkjenne betalinger fra regnskapsprogrammet'
                : 'Du kan betale fra regnskapsprogrammet, men betalingene må ettergodkjennes i nettbanken'
        }}
    </span>
</section>

<section class="selected-bank-service" *ngIf="onboardingService.state()?.BankServices?.HasStatements">
    <i class="material-icons color-good">task_alt</i>
    <span>
        Informasjon om inn- og utbetalinger sendes fra banken til regnskapsprogrammet, oppstart for banksavstemming:
        {{ onboardingService.state()?.BankServices?.BankStatementStartDate | date: 'dd.MM.yyyy' }}
    </span>
</section>
