@switch (theme) {
    @case (themes.EIKA) {
        <init-eika-confirm-and-create />
    }
    @case (themes.SR) {
        <init-sb1-confirm-and-create />
    }
    @default {
        <h1 class="mt-2 mb-2">DEFAULT CONFIRM AND CREATE</h1>
    }
}
