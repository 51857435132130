import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AuthService } from '@app/authService';
import { CompanyService } from '@app/services/common/companyService';
import { Subscription } from 'rxjs';

@Component({
    selector: 'init-sb1-company-created',
    templateUrl: './sb1-company-created.html',
    styleUrls: ['./sb1-company-created.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Sb1CompanyCreated {
    publicSettingsSubscription: Subscription;
    orderOcrLink: string;

    constructor(
        public onboardingService: OnboardingService,
        private authService: AuthService,
        private companyService: CompanyService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.publicSettingsSubscription = this.authService.publicSettingsLoaded$.subscribe((loaded) => {
            if (loaded) {
                const bankUrl = this.authService.publicSettings.BankHomePageUrl;
                if (bankUrl) {
                    const slash = bankUrl?.endsWith('/') ? '' : '/';
                    this.orderOcrLink = `${bankUrl}${slash}nettbank-bedrift/betalingsprodukter/ocr-oversikt`;
                    this.cdr.markForCheck();
                }
            }
        });
    }

    ngOnDestroy() {
        this.publicSettingsSubscription?.unsubscribe();
    }

    setActiveCompany() {
        this.companyService.invalidateCache();
        this.onboardingService.clearSessionStorage();
        this.authService.setActiveCompany(this.onboardingService.createdCompany, '/');
    }

    orderOcr() {
        window.open(this.orderOcrLink, '_blank');
    }
}
