import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { OnboardingService } from '../../onboarding-service';
import { ElsaCustomerPartial } from '@app/models';

@Component({
    selector: 'init-dnb-select-company',
    templateUrl: './dnb-select-company.html',
    styleUrls: ['./dnb-select-company.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DnbSelectCompany {
    theme = theme.theme;
    themes = THEMES;

    mockForm = new UntypedFormGroup(
        {
            CustomerName: new UntypedFormControl('', Validators.required),
            ContractName: new UntypedFormControl(''),
            CompanyName: new UntypedFormControl(''),
            UserName: new UntypedFormControl(''),
        },
        { updateOn: 'blur' },
    );

    constructor(
        private pocService: OnboardingService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        const customer = this.pocService.state()?.Customer;
        if (customer) {
            this.mockForm.patchValue({
                CustomerName: customer.Name,
                ContractName: customer.Contracts[0].Name,
                CompanyName: customer.Contracts[0].CompanyLicenses[0].CompanyName,
                UserName: customer.Contracts[0].CompanyLicenses[0].UserLicenses[0].UserName,
            });

            this.mockForm.markAllAsTouched();
        }

        // emits an event when any form input loses focus (updateOn: 'blur')
        this.mockForm.valueChanges.subscribe(() => this.mockIt());

        this.cdr.markForCheck();
    }

    mockIt() {
        console.log('mockit');
        const formValues = this.mockForm.value;
        const customer: ElsaCustomerPartial = {
            Name: formValues.CustomerName,
            Contracts: [
                {
                    Name: formValues.ContractName,
                    CompanyLicenses: [
                        {
                            CompanyName: formValues.CompanyName,
                            UserLicenses: [
                                {
                                    UserName: formValues.UserName,
                                },
                            ],
                        },
                    ],
                },
            ],
        };

        this.pocService.setCustomer(customer);
    }
}
